const data = [
  {
    title: 'Boyner Akasya',
    address: 'Ankara Devlet Yolu Haydarpaşa Yönü 4. KM Çeçen Sokak Acıbadem',
    town: 'Üsküdar',
    city: 'İstanbul',
    phone: '0 216 510 68 30',
    hours: '10:00 - 22:00',
    locations: [41.001724, 29.054749],
    numberPlate: '34',
    store: 1,
    id: 1,
  },
  {
    title: 'Boyner Akbatı',
    address: 'Sanayi Mah. Esenkent Mevki 1655 Sokak No:6',
    town: 'Beylikdüzü',
    city: 'İstanbul',
    phone: '0 212 397 72 10',
    hours: '10:00 - 22:00',
    locations: [41.056297, 28.667641],
    numberPlate: '34',
    store: 1,
    id: 2,
  },
  {
    title: 'Boyner Antakya Primall',
    address: 'PrimeMall AVM Akasya Mah. Şükrü Balcı Cad. no:203 no:18',
    town: 'Antakya',
    city: 'Hatay',
    phone: '+90 (326) 290-10-40',
    hours: '10:00 - 22:00',
    locations: [36.228729, 36.136402],
    numberPlate: '31',
    store: 1,
    id: 3,
  },
  {
    title: 'Boyner Antalya',
    address: 'Migros Avm Arapsuyu Mah. 100.Yıl Bulvarı Konyaaltı',
    town: 'Konyaaltı',
    city: 'Antalya',
    phone: '+90 (242) 230-14-70',
    hours: '10:00 - 22:00',
    locations: [36.882572, 30.659622],
    numberPlate: '07',
    store: 1,
    id: 4,
  },
  {
    title: 'Boyner Balçova Kipa',
    address: 'Balçova Kipa AVM Mithatpaşa Cad. No: 1462',
    town: 'Balçova',
    city: 'İzmir',
    phone: '+90 (232) 259-18-00',
    hours: '10:00 - 22:00',
    locations: [38.39518, 27.042545],
    numberPlate: '35',
    store: 1,
    id: 5,
  },
  {
    title: 'Boyner Bodrum Midtown',
    address: 'Kemer Mevkii, Ortakent, Midtown Avm Turgutreis Yolu Cumhuriyet Cad. Bodrum',
    town: 'Bodrum',
    city: 'Muğla',
    phone: '+90 (252) 358-76-10',
    hours: '10:00 - 22:00',
    locations: [37.057541, 27.359324],
    numberPlate: '48',
    store: 1,
    id: 6,
  },
  {
    title: 'Boyner Capitol',
    address: 'Capitol Avm Küçük bakkalköy Mah. Tophanelioğlu Cad. Altunizade',
    town: 'Kadıköy',
    city: 'İstanbul',
    phone: '+90 (216) 651-20-54',
    hours: '10:00 - 22:00',
    locations: [41.021065, 29.039572],
    numberPlate: '34',
    store: 1,
    id: 7,
  },
  {
    title: 'Boyner Cepa',
    address: '17.Sokak, Cepa Avm Mustafa Kemal Mah. Eskicity Yolu 7.Km Söğütözü',
    town: 'Söğütözü',
    city: 'Ankara',
    phone: '+90 (312) 219-73-33',
    hours: '10:00 - 22:00',
    locations: [39.910164, 32.778313],
    numberPlate: '06',
    store: 1,
    id: 8,
  },
  {
    title: 'Boyner Çiğli Kipa',
    address: 'Yeni Havaalanı Cd. No:40 Kipa Alışveriş Merkezi İzmir',
    town: 'Çiğli',
    city: 'İzmir',
    phone: '+90 (232) 386-58-00',
    hours: '10:00 - 22:00',
    locations: [38.482098, 27.055601],
    numberPlate: '35',
    store: 1,
    id: 9,
  },
  {
    title: 'Boyner Denizli Forum',
    address: 'Çamlık Mah. Doğan Demircioğlu Cad. No 2 Kat 1-2 Demokrasi Meydanı/Denizli',
    town: 'Denizli',
    city: 'Denizli',
    phone: '+90 (258) 215-10-10',
    hours: '10:00 - 22:00',
    locations: [37.753803, 29.089956],
    numberPlate: '20',
    store: 1,
    id: 10,
  },
  {
    title: 'Boyner Eskicity',
    address: 'Kanatlı Avm Hoşnudiye Mah. İsmet İnönü Cad. Tepebaşı',
    town: 'Tepebaşı',
    city: 'Eskişehir',
    phone: '+90 (222) 231-46-00',
    hours: '10:00 - 22:00',
    locations: [39.778942, 30.513861],
    numberPlate: '26',
    store: 1,
    id: 11,
  },
  {
    title: 'Boyner Gaziantep PrimeMall',
    address: '33.Sok. Gaziantep Prime Mall Osman Gazi Mah. Prof. Dr. Necmettin Erbekan Cad. Şehitkamil',
    town: 'Şehitkamil',
    city: 'Gaziantep',
    phone: '+90 (342) 290-33-05',
    locations: [37.051769, 37.320007],
    numberPlate: '27',
    store: 1,
    id: 12,
  },
  {
    title: 'Boyner Isparta',
    address: 'Süleyman Demirel Bulvarı Bahçelievler Mah.156 Cd.No.33 Kat: 1 Boyner Mgz no: 125/C 32100 Isparta',
    town: 'Merkez',
    city: 'Isparta',
    phone: '+90 (246) 227-28-28',
    hours: '10:00 - 22:00',
    locations: [37.782913, 30.544464],
    numberPlate: '32',
    store: 1,
    id: 13,
  },
  {
    title: 'Boyner İskenderun',
    address: 'Prime Mall Avm Numune Mah. Eyüp Sultan Cad. İskenderun',
    town: 'İskenderun',
    city: 'Hatay',
    phone: '+90 (326) 619-20-80',
    hours: '10:00 - 22:00',
    locations: [36.575169, 36.162491],
    numberPlate: '31',
    store: 1,
    id: 14,
  },
  {
    title: 'Boyner İstanbul Forum',
    address: 'Forum İstanbul Alışveriş Merkezi Kocatepe Mahallesi,Paşa Caddesi 34045 Bayrampaşa',
    town: 'Bayrampaşa',
    city: 'İstanbul',
    phone: '0 212 640 35 75',
    hours: '10:00 - 22:00',
    locations: [41.046207, 28.896301],
    numberPlate: '34',
    store: 1,
    id: 15,
  },
  {
    title: 'Boyner İstinye Park',
    address: 'İstinye Park avm Pınar Mah. İstinye Bayırı Cad. Sarıyer',
    town: 'Sarıyer',
    city: 'İstanbul',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [41.109783, 29.032446],
    numberPlate: '34',
    store: 1,
    id: 16,
  },
  {
    title: 'Boyner İstanbul Hilltown',
    address: 'Hilltown Avm Aydınevler Mah. Siteler Yolu Sk. Maltepe',
    town: 'Maltepe',
    city: 'İstanbul',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [40.952961, 29.122022],
    numberPlate: '34',
    store: 1,
    id: 17,
  },
  {
    title: 'Boyner Kayseri Forum',
    address: 'Kayseri Forum Avm Hunat Mah. Melikgazi',
    town: 'Melikgazi',
    city: 'Kayseri',
    phone: '+90 (352) 231-06-09',
    hours: '10:00 - 22:00',
    locations: [38.723198, 35.497177],
    numberPlate: '38',
    store: 1,
    id: 18,
  },
  {
    title: 'Boyner Konya',
    address: 'Feritpaşa Mah. Kule Cad No:8 Kule Site Alışveriş Merkezi',
    town: 'Selçuklu',
    city: 'Konya',
    phone: '+90 (332) 236-62-62',
    hours: '10:00 - 22:00',
    locations: [37.88953, 32.492764],
    numberPlate: '42',
    store: 1,
    id: 19,
  },
  {
    title: 'Boyner Korupark',
    address: 'Koru Park Avm Adnan Menderes Mah. Mudanya Cad. Emek',
    town: 'Osmangazi',
    city: 'Bursa',
    phone: '+90 (224) 241-46-46',
    hours: '10:00 - 22:00',
    locations: [40.250633, 28.958254],
    numberPlate: '16',
    store: 1,
    id: 20,
  },
  {
    title: 'Boyner Malatya',
    address: 'Malatya Park Avm İnönü Mah. İnönü Cad. Merkez',
    town: 'Merkez',
    city: 'Malatya',
    phone: '+90 (422) 212-74-04',
    hours: '10:00 - 22:00',
    locations: [38.349045, 38.296772],
    numberPlate: '44',
    store: 1,
    id: 21,
  },
  {
    title: 'Boyner Marmara Forum',
    address: 'Marmara Forum AVM Osmaniye Mah. Ekrem Kurt Bulvarı E-5 yolu üzeri MS 005B, MS 011, S190 Bakırköy/İstanbul',
    town: 'Bakırköy',
    city: 'İstanbul',
    phone: '0 212 466 61 41',
    hours: '10:00 - 22:00',
    locations: [40.997131, 28.88443],
    numberPlate: '34',
    store: 1,
    id: 22,
  },
  {
    title: 'Boyner Marmara Park',
    address: 'Marmara Park Avm Güzelyurt Mah. 1.Cad. Esenyurt',
    town: 'Esenyurt',
    city: 'İstanbul',
    phone: '+90 (212) 854-31-04',
    hours: '10:00 - 22:00',
    locations: [41.009811, 28.659027],
    numberPlate: '34',
    store: 1,
    id: 23,
  },
  {
    title: 'Boyner Nautilus',
    address: 'İbrahimağa Mah. Dilenç ve Pilavcıbayırı Sok. Nautilus Avm İçkapı No: Z-49',
    town: 'İstanbul',
    city: 'İstanbul',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [40.99972, 29.03211],
    numberPlate: '34',
    store: 1,
    id: 23,
  },
  {
    title: 'Boyner Mersin',
    address: 'Forum Alışveriş Merkezi Güvenevler Mah. 20. Cadde No:1 Yenicity/Mersin',
    town: 'Yenicity',
    city: 'Mersin',
    phone: '+90 (324) 331-55-35',
    hours: '10:00 - 22:00',
    locations: [36.784481, 34.588634],
    numberPlate: '33',
    store: 1,
    id: 24,
  },
  {
    title: 'Boyner Mersin Marina',
    address: 'Eğriçam Mah. Adnan Menderes Bulvarı No:33 Mersine Marina İç kapı No: KB',
    town: 'Yenicity',
    city: 'Mersin',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [36.77169490139691, 34.57105189537543],
    numberPlate: '33',
    store: 1,
    id: 25,
  },
  {
    title: 'Boyner Panora',
    address: '14.Blok , Panora Avm, Oran Türkiye Ticaret Merkezi  Çankaya',
    town: 'Çankaya',
    city: 'Ankara',
    phone: '+90 (312) 491-48-00',
    hours: '10:00 - 22:00',
    locations: [39.848595, 32.833801],
    numberPlate: '06',
    store: 1,
    id: 26,
  },
  {
    title: 'Boyner Samsun',
    address: 'Samsun Piazza Avm Eski Otogar Mevkii Çarşamba Cad. Merkez',
    town: 'Merkez',
    city: 'Samsun',
    phone: '+90 (362) 290-27-27',
    hours: '10:00 - 22:00',
    locations: [41.276512, 36.353786],
    numberPlate: '55',
    store: 1,
    id: 27,
  },
  {
    title: 'Boyner Trabzon Forum',
    address: 'Değirmendere, Forum Avm Kalkınma Mah. Devlet Sahil Yolu Cad. Merkez',
    town: 'Ortahisar',
    city: 'Trabzon',
    phone: '0 462 330 05 06',
    hours: '10:00 - 22:00',
    locations: [40.999622, 39.76012],
    numberPlate: '61',
    store: 1,
    id: 28,
  },
  {
    title: 'Boyner Ceylan Diyarbakır',
    address: 'Şanlıurfa Yolu 5. Km. Yeni Hal Kavşağı Ceylan Karavil Park Alışveriş Merkezi K:1 Yenicity, Diyarbakır',
    town: 'Yenicity',
    city: 'Diyarbakır',
    phone: '0412 502 04 28',
    hours: '10:00 - 22:00',
    locations: [37.928558, 40.170395],
    numberPlate: '21',
    store: 1,
    id: 29,
  },
  {
    title: 'Boyner Sankopark',
    address: 'Sarıgüllük Mahallesi, Mareşal Fevzi Çakmak Bulvarı Sankopark AVM Kat:1 / R145 Şehitkamil',
    town: 'Şehitkamil',
    city: 'Gaziantep',
    phone: '0 342 339 06 01',
    hours: '10:00 - 22:00',
    locations: [37.063087, 37.361969],
    numberPlate: '27',
    store: 1,
    id: 30,
  },
  {
    title: 'Boyner Cevahir',
    address: 'Meşrutiyet Mah., Büyükdere Cad 22/A, 34400 Şişli/İstanbul',
    town: 'Şişli',
    city: 'İstanbul',
    phone: '0 212 380 03 43',
    hours: '10:00 - 22:00',
    locations: [41.034981, 28.593349],
    numberPlate: '34',
    store: 1,
    id: 31,
  },
  {
    title: 'Boyner Ankamall',
    address: 'Gazi, Gazi Konya Devlet Yolu, Mevlana Blv. No:2, 06560 Yenimahalle/Ankara',
    town: 'Yenimahalle',
    city: 'Ankara',
    phone: '0312 541 10 49',
    hours: '10:00 - 22:00',
    locations: [39.949917, 32.831894],
    numberPlate: '06',
    store: 1,
    id: 32,
  },
  {
    title: 'Boyner Diyarbakır Forum',
    address: 'Forum Diyarbakır Alışveriş Merkezi Mağaza No: FF70-GF62 Yenicity townsi, Şilbe Mahallesi, Ada No: 363 Parsel No: 2 Diyarbakır',
    town: 'Yenicity',
    city: 'Diyarbakır',
    phone: '0.412. 502 29 90',
    hours: '10:00 - 22:00',
    locations: [37.957932, 40.183578],
    numberPlate: '21',
    store: 1,
    id: 33,
  },
  {
    title: 'Boyner Urfa Piazza',
    address: 'Bahçelievler, 11 Nisan Fuar Cad. No:42, Şanlıurfa Merkez/Şanlıurfa',
    town: 'Şanlıurfa Merkez',
    city: 'Şanlıurfa',
    phone: '0414 215 14 14',
    hours: '10:00 - 22:00',
    locations: [37.154362, 38.777847],
    numberPlate: '63',
    store: 1,
    id: 34,
  },
  {
    title: 'Boyner Emaar',
    address: 'Ünalan Mahallesi, Libadiye Cd No:88, 34700 Üsküdar/İstanbul',
    town: 'Üsküdar',
    city: 'İstanbul',
    phone: '0216 510 30 01',
    hours: '10:00 - 22:00',
    locations: [41.003822, 29.072161],
    numberPlate: '34',
    store: 1,
    id: 35,
  },
  {
    title: 'Boyner Hilltown',
    address: 'Aydınevler Mahallesi, Çamlıca Sitesi Yolu Sk., 34765 Maltepe/İstanbul',
    town: 'Maltepe',
    city: 'İstanbul',
    phone: '0216 225 63 00',
    hours: '10:00 - 22:00',
    locations: [40.952923, 29.121729],
    numberPlate: '34',
    store: 1,
    id: 36,
  },
  {
    title: 'Boyner Antalya Agora',
    address: 'Altınova Sinan Mahallesi, Aksekili Sk., 07170',
    town: 'Kepez',
    city: 'Antalya',
    phone: '0 242 999 32 70',
    hours: '10:00 - 22:00',
    locations: [36.913425, 30.762787],
    numberPlate: '07',
    store: 1,
    id: 37,
  },
  {
    title: 'Boyner Serdivan',
    address: 'Arabacı Alanı Mah. Mehmet Akif Ersoy Cad. Serdivan AVM No:62',
    town: 'Merkez',
    city: 'Sakarya',
    phone: '0 264 777 17 60',
    hours: '10:00 - 22:00',
    locations: [40.778305, 30.364754],
    numberPlate: '54',
    store: 1,
    id: 38,
  },
  {
    title: 'Boyner Sakarya Agora',
    address: ' Arabacı Mah. Eski Kazım Paşa Cad. No:14 ',
    town: 'Sakarya',
    city: 'Sakarya',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [40.775477, 30.381651],
    numberPlate: '54',
    store: 1,
    id: 39,
  },
  {
    title: 'Boyner Afyon',
    city: 'Afyon',
    town: 'Afyon',
    locations: [38.755444, 30.554941],
    address: 'Afyon Güvenevler Mah. Park Kapı no: Z-M39',
    phone: '0 272 222 09 11',
    hours: '10:00 - 22:00',
    numberPlate: '03',
    store: 1,
    id: 40,
  },
  {
    title: 'Boyner Kentpark',
    city: 'Ankara',
    town: 'Çankaya',
    locations: [39.909393, 32.775715],
    address: 'Eskicity Yolu üzeri 7. Km. Mustafa Kemal Mah. No:164 Kat: 1- 82C Çankaya',
    phone: '0 312 219 90 61',
    hours: '10:00 - 22:00',
    numberPlate: '06',
    store: 1,
    id: 41,
  },
  {
    title: 'Boyner Alanya',
    city: 'Antalya',
    town: 'Alanya',
    locations: [36.549538, 32.032322],
    address: 'Alanyum Alışveriş Merkezi Cumhuriyet Mah. Çevreyolu Üzeri No:201',
    phone: '0242 515 26 80',
    hours: '10:00 - 22:00',
    numberPlate: '07',
    store: 1,
    id: 42,
  },
  {
    title: 'Boyner Aydın Forum',
    city: 'Aydın',
    town: 'Aydın',
    locations: [37.846985, 27.861235],
    address: 'Orta Mah. Müze Bulvarı No:1 Aydın Forum AVM',
    phone: '0 256 232 02 50',
    hours: '10:00 - 22:00',
    numberPlate: '09',
    store: 1,
    id: 43,
  },
  {
    title: 'Boyner İnegöl',
    city: 'Bursa',
    town: 'İnegöl',
    locations: [40.091335, 29.512659],
    address: 'Osmaniye Mah. Altay Cad. No:2 İnegöl AVM İnegöl',
    phone: '0 224 713 30 41',
    hours: '10:00 - 22:00',
    numberPlate: '16',
    store: 1,
    id: 44,
  },
  {
    title: 'Boyner Suryapı',
    city: 'Bursa',
    town: 'Nilüfer',
    locations: [40.206581, 28.993807],
    address: 'Odunluk Mahallesi, Mihraplı Cd. 6/A, 16110 Nilüfer/Bursa',
    phone: '0224 999 67 10',
    hours: '10:00 - 22:00',
    numberPlate: '16',
    store: 1,
    id: 45,
  },
  {
    title: 'Boyner Çanakkale 17 Burda',
    city: 'Çanakkale',
    town: 'Çanakkale',
    locations: [40.124283, 26.411337],
    address: 'Barbaros Mahallesi Atatürk Caddesi No: 207 Çanakkale - 17 Burda AVM',
    phone: '0286 220 04 21',
    hours: '10:00 - 22:00',
    numberPlate: '17',
    store: 1,
    id: 46,
  },
  {
    title: 'Boyner Maltepe Park',
    city: 'İstanbul',
    town: 'Maltepe',
    locations: [40.919498, 29.163925],
    address: 'Carrefoursa Maltepepark AVM Cevizli M. Tugay yolu C. No:67 Cevizli-Maltepe',
    phone: '0 216 515 11 35',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 1,
    id: 47,
  },
  {
    title: 'Boyner Kars',
    city: 'Kars',
    town: 'Kars',
    locations: [40.599026, 43.091789],
    address: 'Orta Kapı Mah. Seyit Yusuf Bey Cad. No:75 Kars',
    phone: '0 474 212 32 33',
    hours: '10:00 - 22:00',
    numberPlate: '36',
    store: 1,
    id: 48,
  },
  {
    title: 'Boyner 41 Burda',
    city: 'Kocaeli',
    town: 'İzmit',
    locations: [40.75573, 29.946754],
    address: 'Sanayi, Ömer Türkçakal Blv. No:7, 41040 İzmit/Kocaeli',
    phone: '0262 888 4270',
    hours: '10:00 - 22:00',
    numberPlate: '41',
    store: 1,
    id: 49,
  },
  {
    title: 'Boyner Gebze Center',
    city: 'Kocaeli',
    town: 'Gebze',
    locations: [40.796497, 29.442223],
    address: 'Tatlıkuyu Mh. Gebze Center No:107/5 41400 Gebze / Kocaeli',
    phone: '0 262 646 95 51',
    hours: '10:00 - 22:00',
    numberPlate: '41',
    store: 1,
    id: 50,
  },
  {
    title: 'Boyner Kütahya',
    city: 'Kütahya',
    town: 'Kütahya',
    locations: [39.429272, 29.993198],
    address: 'Atatürk Bulvarı (Türk Petrol Yanı)',
    phone: '0 274 225 15 75',
    hours: '10:00 - 22:00',
    numberPlate: '43',
    store: 1,
    id: 51,
  },
  {
    title: 'Boyner Van',
    city: 'Van',
    town: 'Van',
    locations: [38.499836, 43.399422],
    address: 'Mustafa Çohaz Bulvarı No: 115',
    phone: '0 432 212 43 13',
    hours: '10:00 - 22:00',
    numberPlate: '65',
    store: 1,
    id: 52,
  },
  {
    title: 'Boyner Çorlu',
    city: 'Tekirdağ',
    town: 'Çorlu',
    locations: [41.151039, 27.834185],
    address: 'Orion Alışveriş Merkezi No: B 04',
    phone: '0 282 673 46 12',
    hours: '10:00 - 22:00',
    numberPlate: '59',
    store: 1,
    id: 53,
  },
  {
    title: 'Boyner Kahramanmaraş',
    city: 'Kahramanmaraş',
    town: 'Kahramanmaraş',
    locations: [37.576885, 36.93198],
    address: 'Trabzon Caddesi No:49 / Kahramanmaraş',
    phone: '0 344 231 49 42',
    numberPlate: '46',
    store: 1,
    id: 54,
  },
  {
    title: 'Boyner Ayvalık',
    city: 'Balıkesir',
    town: 'Ayvalık',
    locations: [39.319695, 26.692724],
    address: 'Atatürk Cad.No:101 Ayvalık',
    phone: '0 266 312 10 48',
    hours: '10:00 - 22:00',
    numberPlate: '10',
    store: 1,
    id: 55,
  },
  {
    title: 'Boyner 10 Burda Balıkesir',
    city: 'Balıkesir',
    town: 'Balıkesir',
    locations: [39.614449, 27.893696],
    address: '10 Burda AVM Plevne mahallesi Yeni İzmir yolu üzeri mahal alanı GF43 GF44 FF20 Altıeylül Balıkesir',
    phone: '0850 283 1252',
    hours: '10:00 - 22:00',
    numberPlate: '10',
    store: 1,
    id: 56,
  },
  {
    title: 'Boyner Mall Of İstanbul',
    city: 'İstanbul',
    town: 'Başakcity',
    locations: [41.066376, 28.806799],
    address: 'ZİYA GÖKALP MAH.SÜLEYMAN DEMİREL CAD. NO:7 İKİTELLİ KAPI NO:221-418',
    phone: '0850 522 34 84',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 1,
    id: 57,
  },
  {
    title: 'Boyner İstanbul Havalimanı',
    city: 'İstanbul',
    town: 'Arnavutköy',
    locations: [41.259853, 28.742605],
    address: 'Tayakadın Mahallesi Terminal Caddesi No:1 İç Kapı No: 740534',
    phone: '0212 999 57 98',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 1,
    id: 58,
  },
  {
    title: 'Boyner Metropol İstanbul',
    city: 'İstanbul',
    town: 'Atacity',
    locations: [41.000435, 29.121761],
    address: 'Atatürk Mh., Ertuğrul Gazi Cd., No:2Z Metropol İstanbul Alışveriş Merkezi,  D12 Blok B2 Katı No: B2-029  B1 Katı B1- 054',
    phone: '02169994419',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 1,
    id: 59,
  },
  {
    title: 'Boyner İzmir Hilltown',
    city: 'İzmir',
    town: 'Karşıyaka',
    locations: [38.47953, 27.074795],
    address: 'Mavicity Mahallesi 6522 Sokak No:1, Boyner mağazası ;1.Kat GF.L13 ve 2. Kat FF.L19 Karşıyaka Hilltown AVM İzmir',
    phone: '02329990826',
    hours: '10:00 - 22:00',
    numberPlate: '35',
    store: 1,
    id: 60,
  },
  {
    title: 'Boyner Adana 01 Burda',
    city: 'Adana',
    town: 'Seyhan',
    locations: [36.993835, 35.307079],
    address: 'Ahmet Remzi Yüreğir Mah. Cemal Beriker Bulvarı Boyner mağazası No: L08001; L18101;-8134—L18133',
    phone: '3229991443',
    hours: '10:00 - 22:00',
    numberPlate: '01',
    store: 1,
    id: 61,
  },
  {
    title: 'Boyner Vadi',
    city: 'İstanbul',
    town: 'Sarıyer',
    locations: [41.107498, 28.986807],
    address: 'Ayazağa Mah. Cendere Cad. No:109/C',
    phone: '2129777402',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 1,
    id: 62,
  },
  {
    title: 'İzmir Point Bornova',
    city: 'İzmir',
    town: 'Bornova',
    locations: [38.43145, 27.210087],
    address: 'Yeşilova Mah. 4174. Sok. B1-B2 Blok No:84 Point Bornova AVM  Bornova/İzmir  İç Kapı No: Zemin Kat Z02 No , 1.kat 101',
    phone: '0 232 203 11 85',
    hours: '10:00 - 22:00',
    numberPlate: '35',
    store: 1,
    id: 63,
  },
  {
    title: 'Boyner Cadde',
    city: 'İstanbul',
    town: 'Kadıköy',
    locations: [40.966095, 29.070978],
    address: 'Caddebostan Mah. Bağdat Cad. No:319-321 Kadıköy',
    phone: '0216 385 14 09',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 1,
    id: 64,
  },
  {
    title: 'Boyner İzmir İstinye Park',
    city: 'İzmir',
    town: 'Balçova',
    locations: [38.398338, 27.066891],
    address: 'Bahçelerarası Mah. Şehit Bnb. Ali Resmi Tufan Sok. No:3',
    phone: '2329777400',
    hours: '10:00 - 22:00',
    numberPlate: '35',
    store: 1,
    id: 65,
  },
  {
    title: 'Boyner Elazığ',
    city: 'Elazığ',
    town: 'Merkez',
    locations: [38.671165, 39.186707],
    address: 'Cumhuriyet mahallesi, Malatya Cd. No:10/2',
    phone: '0424 502 03 23',
    hours: '10:00 - 22:00',
    numberPlate: '23',
    store: 1,
    id: 66,
  },
  {
    title: 'Boyner Rize',
    city: 'Rize',
    town: 'Rize',
    locations: [41.024525, 40.5326],
    address: 'Rize Şimal Avm İç kapı no : Z-004 / 1-05 / 1-081 -- Müftü Mah. Atatürk Cad. No:548 Eski Stad yeri',
    phone: '464 217 12 17',
    hours: '10:00 - 22:00',
    numberPlate: '53',
    store: 1,
    id: 67,
  },
  {
    title: 'Boyner Edirne Erasta',
    city: 'Edirne',
    town: 'Edirne',
    locations: [41.6665, 26.5709],
    address: 'Edirne Erasta Avm İç kapı no: F01-128/129  Kirişhane Mah. Merkez',
    phone: '',
    store: 1,
    hours: '10:00 - 22:00',
    numberPlate: '22',
    id: 68,
  },
  {
    title: 'Boyner Erzurum MNG',
    city: 'Erzurum',
    town: 'Erzurum',
    locations: [39.911888, 41.250599],
    address: ' MNG Avm İçkapı Lalapaşa Mah. No: 1B-21',
    phone: '',
    store: 1,
    hours: '10:00 - 22:00',
    numberPlate: '25',
    id: 69,
  },
  {
    title: 'Boyner Ermerkez Outlet',
    city: 'Erzincan',
    town: 'Erzincan',
    locations: [39.746366, 39.492623],
    address: 'Ermerkez Avm Karaağaç Mah. Fevzipaşa Cad. İç kapı No: 09-12',
    phone: '',
    store: 1,
    hours: '10:00 - 22:00',
    numberPlate: '24',
    id: 70,
  },
  {
    title: 'Boyner Viaport',
    city: 'İstanbul',
    town: 'Pendik',
    locations: [40.94017, 29.32118],
    address: 'Viaport Avm İç Kapı NO: 300 Yenicity Mah. Pendik/İstanbul',
    phone: '2169777401',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 1,
    id: 71,
  },
  {
    title: 'Boyner Isparta Meydan',
    city: 'Isparta',
    town: 'Merkez',
    locations: [37.76545, 30.550114],
    address: 'Yayla Mah. 118 Cad. C Blok Dıs Kapı No: 27/R İc Kapı No: 3 Merkez Isparta Meydan Avm',
    phone: '246 227 28 28',
    hours: '10:00 - 22:00',
    numberPlate: '32',
    store: 1,
    id: 72,
  },
  {
    title: 'Boyner Pangaltı',
    city: 'İstanbul',
    town: 'Şişli',
    locations: [41.052101, 28.987289],
    address: 'Lotus Pangaltı Halaskargazi Cd No 38 34371 Sisli İstanbul',
    phone: '2129777403',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 1,
    id: 73,
  },
  {
    title: 'Boyner Batman Petrol City',
    city: 'Batman',
    town: 'Merkez',
    locations: [37.920589, 41.136707],
    address: 'Batman Petrol City AVM Gültepe Mah. Yeni Çağ Cad. Aydın Güney Site No:24/A',
    phone: '0488 502 12 49',
    hours: '10:00 - 22:00',
    numberPlate: '72',
    store: 1,
    id: 74,
  },
  {
    title: 'Boyner Dynamic Cevahir',
    city: 'İstanbul',
    town: 'Şişli',
    locations: [41.062801, 28.993],
    address: 'İstanbul Cevahir Alışveriş ve Eğlence Merkezi, Büyükdere Cad. No: 22/A 34400 Şişli Mağaza kapı no: 58 A+60+61+63+64+65',
    phone: '(0212) 380 00 47-48',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 7,
    id: 75,
  },
  {
    title: 'Boyner Dynamic Terracity',
    city: 'Antalya',
    town: 'Lara',
    locations: [36.852501, 30.757601],
    address: 'Fener Mahallesi, Tekelioğlu Caddesi, 1979 Sokak, No: 55 Terra City AVM Lara',
    phone: '0 242 324 61 61',
    hours: '10:00 - 22:00',
    numberPlate: '07',
    store: 7,
    id: 76,
  },
  {
    title: 'Boyner Dynamic Mall Of İstanbul ',
    city: 'İstanbul',
    town: 'Başakcity',
    locations: [41.063133, 28.807451],
    address: 'Ziya Gökalp Mah. Süleyman Demirel Cad. No: 7 Mall Of İstanbul Alışveriş Merkezi” İç Kapı No: 66-050B-050C-051',
    phone: '02129777401',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 7,
    id: 77,
  },
  {
    title: 'Boyner ACity Active',
    city: 'Ankara',
    town: 'Yeni Mahalle',
    locations: [39.947102, 32.76231],
    address: 'Fatih Sultan Mehmet Bulvarı no:244 Yeni Mahalle Ankara Acity Alışveriş Merkezi Zemin kat Mağaza  no: 116',
    phone: '0 (312) 387-36-42',
    hours: '10:00 - 22:00',
    numberPlate: '06',
    store: 2,
    id: 78,
  },
  {
    title: 'Boyner Göksu Outlet',
    city: 'Ankara',
    town: 'Yeni Mahalle',
    locations: [39.986537, 32.647531],
    address: 'Göksu Avm Göksu Mah. Selçuklular Cad. No:57',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '06',
    store: 2,
    id: 79,
  },
  {
    title: 'Boyner Active Buyaka',
    city: 'İstanbul',
    town: 'Ümraniye',
    locations: [41.026691, 29.12682],
    address: 'Fatih Sultan Mehmet Mahallesi Balkan Caddesi No:56 B/160 Ümraniye/İstanbul Buyaka Avm B2 Kat İç Kapı No:20',
    phone: '2169777400',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 2,
    id: 80,
  },
  {
    title: 'Boyner Active Fethiye',
    city: 'Muğla',
    town: 'Fethiye',
    locations: [36.62421, 29.13269],
    address: 'Taşyaka Mah. Ölüdeniz Cad. No:11 Fethiye',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '48',
    store: 2,
    id: 81,
  },
  {
    title: 'Boyner Rüya 48 Avm',
    city: 'Muğla',
    town: 'Menteşe',
    locations: [37.255062, 28.228964],
    address: 'Paşapınarı Mah. Ticaret Alanı (PSP) Sk. 2 C Muğla',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '48',
    store: 2,
    id: 82,
  },
  {
    title: 'Fabrika Ankamall',
    city: 'Ankara',
    town: '',
    locations: [39.951092, 32.831108],
    address: 'Konya Devlet Yolu Mevlana Blv. No:2 K:3 Ankamall Alışveriş Merkezi',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '06',
    store: 3,
    id: 83,
  },
  {
    title: 'Boyner Outlet Canpark ',
    city: 'İstanbul',
    town: 'Ümraniye',
    locations: [41.025127, 29.106848],
    address: 'Canpark AVM Yamanevler Mah. Alemdağ Cad. No: 169 İç Kapı No: 61-62 Ümraniye / İstanbul  ',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 4,
    id: 84,
  },
  {
    title: 'Boyner Outlet Olivium ',
    city: 'İstanbul',
    town: 'Zeytinburnu',
    locations: [40.995588, 28.909906],
    address: 'BEŞTELSİZ MAH. PROF. DR. MUAMMER AKSOY CAD. OLİVİUM OUTLET CENTER 30 57 ZEYTİNBURNU İSTANBUL',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 4,
    id: 85,
  },
  {
    title: 'Boyner Outlet Starcity',
    city: 'İstanbul',
    town: 'Yenibosna',
    locations: [41.01133, 28.815979],
    address: 'Starcity AVM 1. Kat Köyaltı Mevkii Merkez Mahallesi Değirmenbahçe Caddesi Kavak Sokak No:2 Yenibosna',
    phone: '+90 (212) 603-40-84',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 4,
    id: 86,
  },
  {
    title: 'Boyner Outlet İkitelli',
    city: 'İstanbul',
    town: 'Küçükçekmece',
    locations: [41.070778, 28.809704],
    address: 'Deposite Outlet İş Mrk. 1.Bodrum İkitelli OSB.',
    phone: '0 212 671 48 08',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 4,
    id: 87,
  },
  {
    title: 'Boyner Outlet Optimum',
    city: 'İstanbul',
    town: 'Kadıköy',
    locations: [40.995262, 29.087025],
    address: 'E-5 üzeri İstiklal Sok 10/4 - 1K97 Yenisahra',
    phone: '0 216 664 18 90',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 4,
    id: 88,
  },
  {
    title: 'Boyner Ordu Cadde',
    city: 'Ordu',
    town: 'AltınOrdu',
    locations: [40.985293149767976, 37.87899546680438],
    address: 'Sarkiye Mah.Sırrıpaşa Mah. No:120-A Altınordu ',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '52',
    store: 4,
    id: 89,
  },
  {
    title: 'Boyner Outlet Eskicity Neo',
    city: 'Eskişehir',
    town: 'Odunpazarı',
    locations: [39.780399, 30.47761],
    address: 'Eskicity Kütahya Yolu Üzeri Çamlıca Mah Gülperi Sokak NEO AVM',
    phone: '0 222 310 42 22',
    hours: '10:00 - 22:00',
    numberPlate: '26',
    store: 4,
    id: 90,
  },
  {
    title: 'Boyner Outlet Park Bornova',
    city: 'İzmir',
    town: 'Bornova',
    locations: [38.457012, 27.246857],
    address: 'Park Bornova Outlet Ankara Caddesi No:192 35040 Bornova',
    phone: '0 232 373 96 00',
    hours: '10:00 - 22:00',
    numberPlate: '35',
    store: 4,
    id: 91,
  },
  {
    title: 'Boyner Outlet 212',
    city: 'İstanbul',
    town: 'Bağcılar',
    locations: [41.047905, 28.811941],
    address: '212  Avm. Mahmutbey Merkez Mah. Taşocağı Cad. No: 5 34217 Bağcılar',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 4,
    id: 92,
  },
  {
    title: 'Boyner Outlet Ankara Optimum',
    city: 'Ankara',
    town: 'Etimesgut',
    locations: [39.968323, 32.631119],
    address: 'Etiler Mah. Ayaş Ankara Yolu Bulvarı No:93 Boyner Mağazası; ZK-2-3-4 no Etimesgut – Ankara',
    phone: '0 312 281 41 30',
    hours: '10:00 - 22:00',
    numberPlate: '06',
    store: 4,
    id: 93,
  },
  {
    title: 'Boyner Outlet Westpark',
    city: 'İzmir',
    town: 'Karşıyaka',
    locations: [38.4856, 27.178076],
    address: 'R.Şevket İnce mah. 2148/9 Sokak No:1/28-29-30',
    phone: '0232 570 09 30',
    hours: '10:00 - 22:00',
    numberPlate: '35',
    store: 4,
    id: 94,
  },
  {
    title: 'Boyner Outlet Selway',
    city: 'İzmir',
    town: 'Balçova',
    locations: [38.376488, 27.073483],
    address: 'Balçova Çeşme Karayolu Çetin Emeç mah. Selway Outlet AVM A Blok Kıvanç sok. No 10B İç kapı 1B04',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '35',
    store: 4,
    id: 95,
  },
  {
    title: 'Boyner Outlet SiirtPark',
    city: 'Siirt',
    town: '',
    locations: [37.937122, 41.925613],
    address: 'Kooperatif Mah. Hükümet Bulvarı Cad. No:88/1 Magaza no: 6-7-8-9 Siirt',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '56',
    store: 4,
    id: 96,
  },
  {
    title: 'Boyner Outlet KUMSMALL',
    city: 'Kayseri',
    town: 'Kocasinan',
    locations: [38.778812, 35.426956],
    address: 'KUMSMALL FACTORY ALIŞVERİŞ MERKEZİ Oymaağaç mah. 5062 cad. Zemin kat ZK-M NO:102-103 116-069 KOCASİNAN/KAYSERİ',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '38',
    store: 4,
    id: 97,
  },
  {
    title: 'Boyner Outlet Uşak Festiva',
    city: 'Uşak',
    town: 'Merkez',
    locations: [38.679955, 29.441011],
    address: 'Sarayaltı Mahallesi Gazi Bulvarı No:173 Merkez Uşak',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '64',
    store: 4,
    id: 98,
  },
  {
    title: 'Boyner Outlet Beylikdüzü Anpa',
    city: 'İstanbul',
    town: 'Esenyurt',
    locations: [41.015759, 28.67391],
    address: 'Güzelyurt Mah. Mustafa Kemal Cad. 38.Sok No:6 (E-5 Karayolu üzeri ) Anpa Avm Haramidere İc Kapı No: M83+E57+E58 Esenyurt',
    phone: '02129995710',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 4,
    id: 99,
  },
  {
    title: 'Boyner Outlet Bolu Highway',
    city: 'Bolu',
    town: '',
    locations: [40.722736, 31.466614],
    address: 'Ankara-İstanbul otoyolu 227.km elmalık mevkii No:5/z0095 Bolu Highway Avm No:z120',
    phone: '0374 999 16 22',
    hours: '10:00 - 22:00',
    numberPlate: '14',
    store: 4,
    id: 100,
  },
  {
    title: 'Boyner Outlet Samsun Lovelet',
    city: 'Samsun',
    town: 'Canik',
    locations: [41.265499, 36.379211],
    address: 'Toptepe mahallesi hacı mansur sokak No:3 CanikSAMSUN Lovelet Avm',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '55',
    store: 4,
    id: 101,
  },
  {
    title: 'Boyner Outlet Alanya Konaklı',
    city: 'Antalya',
    town: 'Alanya',
    locations: [36.589901, 31.859619],
    address: 'Konaklı Mah. Mustafa Kemal Bulv. No:81C Alanya-Antalya – Mall of Konaklı Avm İç kapı no: 62-63-64-65-66',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '07',
    store: 4,
    id: 102,
  },
  {
    title: 'Boyner Outlet Kartal Anpa',
    city: 'İstanbul',
    town: 'Kartal',
    locations: [40.912159, 29.21174],
    address: 'Orta Mahallesi Yalnız Selvi Caddesi No:30 Kartal/İstanbul  ’’Anpa Avm Kartal’’ -1.Kat İç kapı No: 1B+1C',
    phone: '216 9992267',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 4,
    id: 103,
  },
  {
    title: 'Boyner Outlet Afyon Watermall',
    city: 'Afyon',
    town: 'Merkez',
    locations: [38.789982, 30.460007],
    address: 'Dörtyol Mah. Turgut Özal Cad. No:38 Merkez/Afyon  -- Watermall Avm İç kapı no: 4-5-6-7-9',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '03',
    store: 4,
    id: 104,
  },
  {
    title: 'Boyner Active Batman',
    city: 'Batman',
    town: 'Merkez',
    locations: [37.90403, 41.131348],
    address: 'Batman Park Avm Kültür Mah. Turgut Özal Bulvarı Merkez',
    phone: '+90 (488) 290-11-57',
    hours: '10:00 - 22:00',
    numberPlate: '72',
    store: 2,
    id: 105,
  },
  {
    title: 'Boyner Active Adana',
    city: 'Adana',
    town: 'Yüreğir',
    locations: [36.99155, 35.339466],
    address: 'Adana Optimum Alışveriş ve Eğlence Merkezi | Hacı Sabancı Bulvarı No:28 Yüreğir, Adana',
    phone: '0 322 333 33 31',
    hours: '10:00 - 22:00',
    numberPlate: '01',
    store: 5,
    id: 106,
  },
  {
    title: 'Boyner Active Adıyaman',
    city: 'Adıyaman',
    town: 'Merkez',
    locations: [37.753021, 38.239483],
    address: 'Adıyaman Park AVM Yeni Sanayi Mah. 2819 Sk. No.70 Merkez Adıyaman',
    phone: '0416 202 18 05',
    hours: '10:00 - 22:00',
    numberPlate: '02',
    store: 2,
    id: 107,
  },
  {
    title: 'Boyner Active MarkAntalya',
    city: 'Antalya',
    town: '',
    locations: [36.893353, 30.704264],
    address: 'Tahılpazarı Mah., Kazım Özalp Caddesi No:84, 07025',
    phone: '0242 999 10 97',
    hours: '10:00 - 22:00',
    numberPlate: '07',
    store: 5,
    id: 108,
  },
  {
    title: 'Boyner Active Alanya',
    city: 'Antalya',
    town: 'Alanya',
    locations: [36.544777, 32.002934],
    address: 'Şekerhane Mah. Hacet Cad. No:7 Alanya / ANTALYA',
    phone: '(0242) 502 63 91',
    hours: '10:00 - 22:00',
    numberPlate: '07',
    store: 2,
    id: 109,
  },
  {
    title: 'Boyner Active İzmir Optimum',
    city: 'İzmir',
    town: 'Gaziemir',
    locations: [38.339008, 27.13488],
    address: 'Beyazevler Mah. Akçay Cad. No: 103 İç kapı No: 1K-049 / 106 Gaziemir',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '35',
    store: 2,
    id: 110,
  },
  {
    title: 'YKM Adana',
    city: 'Adana',
    town: '',
    locations: [37.01783, 35.242126],
    address: 'Öğretmenler Bulvarı M1 Tepe AVM No:4',
    phone: '0 322 271 01 04',
    hours: '10:00 - 22:00',
    numberPlate: '01',
    store: 6,
    id: 111,
  },
  {
    title: 'YKM Konak',
    city: 'İzmir',
    town: 'Konak',
    locations: [38.416355, 27.127989],
    address: 'SSK Sitesi E Blok No:9/B',
    phone: '0 232 441 49 51',
    hours: '10:00 - 20:30 (Pazar: 11:00 - 20:00)',
    numberPlate: '35',
    store: 6,
    id: 112,
  },
  {
    title: 'YKM Mersin Forum',
    city: 'Mersin',
    town: '',
    locations: [36.784397, 34.589848],
    address: 'Forum Mersin AVM Güven Evler Mah. 20.Cad. No : 1 FS Blok 48',
    phone: '0 324 331 52 30',
    hours: '10:00 - 22:00',
    numberPlate: '33',
    store: 6,
    id: 113,
  },
  {
    title: 'Costa Coffee Cadde',
    city: 'İstanbul',
    town: 'ŞAŞKINBAKKAL',
    locations: [40.96606189667945, 29.070935810130507],
    address: 'Caddebostan Mah. Bağdat Cad. No:319 Kadıköy/İstanbul',
    phone: '',
    hours: '07:30 - 23:00',
    numberPlate: '34',
    store: 8,
    id: 114,
  },
  {
    title: 'Costa Coffee Pangaltı',
    city: 'İstanbul',
    town: 'PANGALTI',
    locations: [41.05174730921157, 28.987697222760687],
    address: 'Costa Lotus Pangaltı, Halaskargazi Cd. No:38  34371 Şişli / İstanbul',
    phone: '',
    hours: '07:00 - 22:00',
    numberPlate: '34',
    store: 8,
    id: 115,
  },
  {
    title: 'Costa Coffee Acity',
    city: 'Ankara',
    town: 'YENİMAHALLE',
    locations: [39.94772188237332, 32.762324335122344],
    address: 'Fatih Sultan Mehmet Bulvarı No: 24 Yeni mahalle Ankara Acity AVM Zemin Kat Mağaza No: 116/ Ankara',
    phone: '',
    hours: '07:30 - 22:30',
    numberPlate: '06',
    store: 8,
    id: 117,
  },
  {
    title: 'Costa Coffee Ankamall',
    city: 'Ankara',
    town: 'YENİMAHALLE',
    locations: [39.95006044322866, 32.83141915426539],
    address: 'Mevlana Bulvarı No:2 /3001 Yenimahalle-Ankara / Ankamall AVM 1.Kat İç Kapı No:1004',
    phone: '',
    hours: '09:00 - 22:30',
    numberPlate: '06',
    store: 8,
    id: 118,
  },
  {
    title: 'Costa Coffee Midtown',
    city: 'BODRUM',
    town: '',
    locations: [37.05833567794899, 27.35781113004417],
    address: 'Cumhuriyet Cad. Kemer Mevkii No: 6 Ortakent 48200 Bodrum – Muğla / Midtown Avm İç Kapı No: B01-01',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '48',
    store: 8,
    id: 119,
  },
  {
    title: 'Costa Coffee İstinyePark',
    city: 'İSTANBUL',
    town: 'SARIYER',
    locations: [41.110844757222786, 29.032336627335535],
    address: 'İstinyepark Alışveriş Merkezi Boyner 34460 Sarıyer/İstanbul ',
    phone: '',
    hours: '10:00 - 22:00',
    numberPlate: '34',
    store: 8,
    id: 120,
  },
  {
    title: 'Boyner İzmir Çiğli Kipa',
    address: 'YENİ HAVAALANI CAD. NO = 40 KİPA AVM',
    town: 'Çiğli',
    city: 'İzmir',
    phone: '+90 (232) 386 58 00',
    hours: '10:00 - 22:00',
    locations: [38.482474, 27.05556],
    numberPlate: '35',
    store: 1,
    id: 121,
  },
  {
    title: 'Boyner İskenderun Prime Mall',
    address: 'PRİME MALL ALIŞVERİŞ MERKEZİ, NUMUNE MAH. EYÜP SULTAN CADDESİ KAT:1 NO:3',
    town: 'İskenderun',
    city: 'Hatay',
    phone: '+90 (326) 619 20 80',
    hours: '10:00 - 22:00',
    locations: [36.575103128414, 36.16241236914],
    numberPlate: '31',
    store: 1,
    id: 122,
  },
  {
    title: 'Boyner İskenderun Forbes',
    address: 'Süleymaniye, Kazım Karabekir Cd. No: 97/1',
    town: 'İskenderun',
    city: 'Hatay',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [41.018896, 28.96611],
    numberPlate: '31',
    store: 1,
    id: 123,
  },
  {
    title: 'Boyner Balıkesir Edremit Novada',
    address: 'Edremit Novada Alışveriş Merkezi Sezgin İnan Caddesi No:104',
    town: 'Edremit',
    city: 'Balıkesir',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [39.5992023430654, 26.9838662393127],
    numberPlate: '10',
    store: 1,
    id: 124,
  },
  {
    title: 'Boyner Outlet Çerkezköy',
    address: 'ÇERKEZKÖY OSB TİCARET BLOKLARI 1. BLOK, NO:04',
    town: 'ÇERKEZKÖY',
    city: 'Tekirdağ',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [41.2867290453176, 27.9859365793137],
    numberPlate: '59',
    store: 4,
    id: 125,
  },
  {
    title: 'Boyner Bodrum Shop & Go Pop up',
    address: 'Torba Mah. Rıza Anter Cad. No:11 Shop&Go Avm İç kapı No: 17-18',
    town: 'BODRUM',
    city: 'Muğla',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [37.0650690986375, 27.4641536668639],
    numberPlate: '48',
    store: 4,
    id: 126,
  },
  {
    title: 'Boyner Outlet Göztepe',
    address: 'E-5 ÜZERİ İSTİKLAL SOK 10/4 - 1K97',
    town: 'Ataşehir',
    city: 'İstanbul',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [40.9892952273333, 29.0854573030029],
    numberPlate: '34',
    store: 4,
    id: 127,
  },
  {
    title: 'Boyner Outlet Adana Optımum Pop Up',
    address: 'HACI SABANCI BULVARI NO:28 ADANA/OPTİMUM AVM ZM19',
    town: 'YÜREĞİR',
    city: 'Adana',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [38.7324077639965, 35.5153308844246],
    numberPlate: '01',
    store: 4,
    id: 128,
  },
  {
    title: 'Boyner Bursa Downtown',
    address: 'Bursa Downtown AVM.İstiklal mah.Yunuseli bulvarı no:13/1 Osmangazi Bursa',
    town: 'Osmangazi',
    city: 'BURSA',
    phone: '2249992309',
    hours: '10:00 - 22:00',
    locations: [40.22225, 29.01291],
    numberPlate: '16',
    store: 1,
    id: 129,
  },
  {
    title: 'Boyner Manisa Meydan',
    address: 'Manisa Meydan Avm İç kapı No:8-12 / Uncubozköy Mah. 5515 Sok. No:41 Yunusemre/Manisa',
    town: 'Yunusemre',
    city: 'Manisa',
    phone: '2369991668',
    hours: '10:00 - 22:00',
    locations: [38.61138, 27.38217],
    numberPlate: '45',
    store: 1,
    id: 130,
  },
  {
    title: 'Boyner Kemer Antalya',
    address: 'Yeni Mah. Dr. Dervişoğlu dörtyol bulv. No:14/A Kemer/Antalya',
    town: 'Kemer',
    city: 'ANTALYA',
    phone: '2429669807',
    hours: '10:00 - 22:00',
    locations: [36.60152, 30.55563],
    numberPlate: '07',
    store: 1,
    id: 131,
  },
  {
    title: 'Boyner Istanbul Costa Temaworld',
    address: 'Atakent Mah. 243. Sk. Dış Kapı No:28/2. Blok B-06 Bağımsız Bölüm  Küçükçekmece / İstanbul',
    town: 'Küçükçekmece',
    city: 'İstanbul',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [41.09430693945175, 28.794411881342175],
    numberPlate: '34',
    store: 1,
    id: 132,
  },
  {
    title: 'Boyner Istanbul Temaworld',
    address: 'Atakent Mah. Tema World Avm 2.Blok İç kapı No: B-01,02,03,04,05',
    town: 'Atakent',
    city: 'İstanbul',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [41.055185403769315, 28.772927824130413],
    numberPlate: '34',
    store: 1,
    id: 133,
  },
  {
    title: 'Boyner Land of Legends Avm',
    address: 'Kadriye Mah. Atatürk 3 Cad. No:1 Serik / Antalya',
    town: 'Serik',
    city: 'ANTALYA',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [36.878166355377445, 31.005275051685206],
    numberPlate: '07',
    store: 1,
    id: 134,
  },
  {
    title: 'Boyner Istanbul Umraniye Meydan',
    address: 'Fatih Sultan Mehmet Mah. Balkan Cad. No:62 İç kapı No: KAVM 529-530-531',
    town: 'Umraniye',
    city: 'UMRANIYE',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [41.024073543924615, 29.124583881338694],
    numberPlate: '07',
    store: 1,
    id: 135,
  },
  {
    title: 'Boyner Iğdır Cadde',
    address: 'Söğütlü Mah. Karabağ Cad. No:2/A',
    town: 'Söğütlü',
    city: 'IGDIR',
    phone: '',
    hours: '10:00 - 22:00',
    locations: [39.93395121882884, 44.015484411971904],
    numberPlate: '76',
    store: 1,
    id: 136,
  },
];

export const store = [
  {
    id: 0,
    name: 'Tüm Mağazalar',
  },
  {
    id: 1,
    name: 'Boyner Büyük Mağazacılık',
  },
  {
    id: 2,
    name: 'Boyner Active',
  },
  {
    id: 7,
    name: 'Boyner Dynamic',
  },
  {
    id: 4,
    name: 'Boyner Outlet',
  },
  {
    id: 3,
    name: 'Fabrika',
  },
  {
    id: 6,
    name: 'YKM',
  },
  {
    id: 8,
    name: 'Costa Coffee',
  },
];

export default data;
